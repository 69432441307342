var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"upload-img-box-9136076486841527",style:({ width: _vm.record.options.width })},[_c('Upload',{attrs:{"name":_vm.config.uploadImageName || _vm.record.options.fileName,"headers":_vm.config.uploadImageHeaders || _vm.record.options.headers,"data":_vm.config.uploadImageData || _vm.optionsData,"action":_vm.config.uploadImage || _vm.record.options.action,"multiple":_vm.record.options.multiple,"listType":_vm.record.options.listType,"disabled":_vm.record.options.disabled || _vm.parentDisabled,"fileList":_vm.fileList,"accept":"image/gif, image/jpeg, image/png","remove":_vm.remove,"beforeUpload":_vm.beforeUpload},on:{"change":_vm.handleChange,"preview":_vm.handlePreview}},[(
        _vm.record.options.listType !== 'picture-card' &&
          _vm.fileList.length < _vm.record.options.limit
      )?_c('Button',{attrs:{"disabled":_vm.record.options.disabled || _vm.parentDisabled}},[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" "+_vm._s(_vm.record.options.placeholder)+" ")],1):_vm._e(),(
        _vm.record.options.listType === 'picture-card' &&
          _vm.fileList.length < _vm.record.options.limit
      )?_c('div',{attrs:{"disabled":_vm.record.options.disabled || _vm.parentDisabled}},[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v(_vm._s(_vm.record.options.placeholder))])],1):_vm._e()],1),_c('a-modal',{attrs:{"visible":_vm.previewVisible,"footer":null},on:{"cancel":_vm.handleCancel}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"alt":"example","src":_vm.previewImageUrl}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }